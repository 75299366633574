<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title.ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <form>
                        <b-overlay :show="loading" class="p-1" rounded>
                            <div class="row mb-4">
                                <cropper
                                    v-if="model.image_file && cropper"
                                    v-model="model.image"
                                    :file="model.image_file"
                                    @update:file="(file) => model.image_file = file"
                                ></cropper>
                                <label
                                    :class="{'text-danger': states.image_file === false}"
                                    class="col-sm-3 col-form-label"
                                    for="image-file-input"
                                >
                                    <img v-if="model.image" :src="model.image" alt="" class="rounded-circle avatar-lg"/>
                                    <div v-else class="avatar-sm">
                                        <span class="avatar-title rounded-circle bg-primary text-white font-size-16"> {{
                                                letter
                                            }} </span>
                                    </div>
                                </label
                                >
                                <div class="col-sm-9 align-items-center d-flex">
                                    <input
                                        id="image-file-input"
                                        :class="{'border-danger': states.image_file === false}"
                                        class="form-control"
                                        name="image_file"
                                        type="file"
                                        @change="uploadImage"
                                    />
                                    <div :class="{'d-block': states.image_file === false}" class="invalid-feedback">
                                        {{ errors.image_file }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.prefix === false}"
                                    class="col-sm-3 col-form-label"
                                    for="prefix-input"
                                >{{ $t('organizations::contributors.columns.prefix').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="prefix-input"
                                        v-model="model.prefix"
                                        :class="{'border-danger': states.prefix === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.prefix').ucFirst()"
                                        class="form-control"
                                        name="prefix"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.prefix === false}" class="invalid-feedback">
                                        {{ errors.prefix }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="!name_order" class="row mb-4">
                                <label
                                    :class="{'text-danger': states.firstname === false}"
                                    class="col-sm-3 col-form-label"
                                    for="firstname-input"
                                >{{ $t('organizations::contributors.columns.firstname').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="firstname-input"
                                        v-model="model.firstname"
                                        :class="{'border-danger': states.firstname === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.firstname')"
                                        class="form-control"
                                        name="firstname"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                                        {{ errors.firstname }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.lastname === false}"
                                    class="col-sm-3 col-form-label"
                                    for="lastname-input"
                                >{{ $t('organizations::contributors.columns.lastname').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="lastname-input"
                                        v-model="model.lastname"
                                        :class="{'border-danger': states.lastname === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.lastname').ucFirst()"
                                        class="form-control"
                                        name="lastname"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.lastname === false}" class="invalid-feedback">
                                        {{ errors.lastname }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="name_order" class="row mb-4">
                                <label
                                    :class="{'text-danger': states.firstname === false}"
                                    class="col-sm-3 col-form-label"
                                    for="firstname-input"
                                >{{ $t('organizations::contributors.columns.firstname').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="firstname-input"
                                        v-model="model.firstname"
                                        :class="{'border-danger': states.firstname === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.firstname')"
                                        class="form-control"
                                        name="firstname"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                                        {{ errors.firstname }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.name === false}"
                                    class="col-sm-3 col-form-label"
                                    for="name-input"
                                >{{ $t('organizations::contributors.columns.name').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="name-input"
                                        v-model="model.name"
                                        :class="{'border-danger': states.name === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.name').ucFirst()"
                                        class="form-control"
                                        name="name"
                                        readonly
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">
                                        {{ errors.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    class="col-sm-3 col-form-label"
                                    for="name-type-input"
                                >{{ $t('users.name_order').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                                        <label class="form-check-label"
                                               for="name-type-input">{{ $t('users.western_name_order') }}</label>
                                        <input id="name-type-input" v-model="name_order"
                                               class="form-check-input float-none mx-3" type="checkbox">
                                        <label class="form-check-label"
                                               for="name-type-input">{{ $t('users.eastern_name_order') }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.gender_id === false}"
                                    class="col-sm-3 col-form-label"
                                    for="gender_id"
                                >{{ $tc('organizations::genders.gender', 1).ucFirst() }}</label>
                                <div class="col-sm-9">
                                    <select
                                        id="gender_id"
                                        v-model="model.gender_id"
                                        :class="{'border-danger': states.gender_id === false}"
                                        class="form-select"
                                        name="gender_id"
                                    >
                                        <option :value="model.id ? null : undefined">
                                            {{ $tc('organizations::genders.gender', 1).ucFirst() }}
                                        </option>
                                        <option v-for="(item, key) in genders" :key="key" :value="item.id">{{
                                                item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                                            }}
                                        </option>
                                    </select>
                                    <div :class="{'d-block': states.gender_id === false}" class="invalid-feedback">
                                        {{ errors.gender_id }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.email === false}"
                                    class="col-sm-3 col-form-label"
                                    for="email-input"
                                >{{ $t('organizations::contributors.columns.email').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="email-input"
                                        v-model="model.email"
                                        :class="{'border-danger': states.email === false}"
                                        :placeholder="$t('organizations::contributors.placeholders.email').ucFirst()"
                                        class="form-control"
                                        name="email"
                                        type="email"
                                    />
                                    <div :class="{'d-block': states.email === false}" class="invalid-feedback">
                                        {{ errors.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.phone === false}"
                                    class="col-sm-3 col-form-label"
                                    for="phone-input"
                                >{{ $t('organizations::contributors.columns.phone').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <vue-tel-input
                                        id="phone-input"
                                        v-model="phone"
                                        :inputOptions="{
                                            id: 'phone-input',
                                            placeholder: $t('organizations::contributors.placeholders.phone').ucFirst(),
                                            styleClasses: {'border-danger': states.phone === false}
                                        }"
                                        :invalidMsg="errors.phone"
                                        defaultCountry="hu"
                                        validCharactersOnly
                                        mode="international"
                                    ></vue-tel-input>
                                    <div :class="{'d-block': states.phone === false}" class="invalid-feedback">
                                        {{ errors.phone }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.authenticable === false}"
                                    class="col-sm-3 form-check-label"
                                    for="is-active-input"
                                >{{ $t('organizations::contributors.columns.authenticable').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="is-active-input"
                                        v-model="model.authenticable"
                                        :class="{'border-danger': states.authenticable === false}"
                                        class="form-check-input"
                                        name="authenticable"
                                        type="checkbox"
                                    />
                                    <div :class="{'d-block': states.authenticable === false}" class="invalid-feedback">
                                        {{ errors.authenticable }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.is_active === false}"
                                    class="col-sm-3 form-check-label"
                                    for="is-active-input"
                                >{{ $t('organizations::contributors.columns.is_active').ucFirst() }}</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="is-active-input"
                                        v-model="model.is_active"
                                        :class="{'border-danger': states.is_active === false}"
                                        class="form-check-input"
                                        name="is_active"
                                        type="checkbox"
                                    />
                                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                                        {{ errors.is_active }}
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end">
                                <div class="col-sm-9">
                                    <div>
                                        <button class="btn btn-primary w-md" type="submit" @click.prevent="save">
                                            {{ $t('base.save').ucFirst() }}
                                        </button>
                                        <slot :save="save" name="buttons"></slot>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </form>
                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Cropper from "../elements/cropper.vue";

export default {
    name: "ContributorForm",

    emits: ['saved'],

    components: {Cropper},

    props: {
        contributor: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'contributor/loading',
            'authenticated': 'contributor/authenticated',
            'states': 'contributor/states',
            'errors': 'contributor/errors',
            genders: 'gender/all'
        }),

        title: function () {
            return this.$t('base.' + (this.model.id ? 'edit_item' : 'create_new_item'), this.model.id ? {item: this.model.name} : {item: this.$tc('organizations::contributors.contributor', 1)})
        },

        letter: function () {
            return this.model && this.model.firstname ? this.model.firstname[0] : '?'
        },
    },

    data: function () {
        return {
            model: Object.assign({is_active: true}, JSON.parse(JSON.stringify(this.contributor))),
            change: {
                password: null,
                new: null,
                new_confirmation: null,
                id: null
            },
            name_order: true, // false = western, true = eastern
            cropper: false,
            customPassword: false,
            height: '100%',
            phone: (this.contributor && this.contributor.phone) || ''
        }
    },

    methods: {
        uploadImage: function (e) {
            if (e.target.files.length) {
                const file = e.target.files[0];
                this.model.image = file ? URL.createObjectURL(file) : this.model.image;
                this.model.image_file = e.target.files[0]
                this.cropper = true
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            let data = Object.assign({}, this.model, {is_active: this.model.is_active === true ? 1 : 0})

            delete data.image

            return this.$store.dispatch(`contributor/${method}`, data).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.cropper = false
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/contributors/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        /*changePassword: function () {
            this.$store.dispatch('user/changePassword', Object.assign({}, this.change, {id: this.user.id})).then(() => {
                this.change = {
                    password: null,
                    new: null,
                    new_confirmation: null,
                    id: null
                }
            });
        },*/

        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        }
    },

    watch: {
        'model.firstname': function (val) {
            if (val && this.model.lastname) {
                this.model.name = this.name_order ? this.model.lastname + ' ' + val : val + ' ' + this.model.lastname;
            } else if (val) {
                this.model.name = val;
            } else if (this.model.lastname) {
                this.model.name = this.model.lastname;
            } else {
                this.model.name = '';
            }
        },

        'model.lastname': function (val) {
            if (val && this.model.firstname) {
                this.model.name = this.name_order ? val + ' ' + this.model.firstname : this.model.firstname + ' ' + val;
            } else if (val) {
                this.model.name = val;
            } else if (this.model.firstname) {
                this.model.name = this.model.firstname;
            } else {
                this.model.name = '';
            }
        },

        name_order: function (val) {
            if (this.model.firstname && this.model.lastname) {
                this.model.name = val ? this.model.lastname + ' ' + this.model.firstname : this.model.firstname + ' ' + this.model.lastname;
            }
        },

        contributor: function (contributor) {
            this.model = JSON.parse(JSON.stringify(contributor))
            this.phone = (contributor && contributor.phone) || ''
        },

        phone: function (value) {
            this.model.phone = value
            //.replace(/\s|\t|\n|\+/g, '')
        },
    },

    created() {
        this.$store.dispatch('gender/all')
    }
}
</script>

<style scoped>
@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
