<script>
import {mapGetters} from "vuex";
import TransitionToggle from "../../transitions/toggle.vue";
import _ from "lodash";
import PositionLine from "../position-line.vue";

export default {
    name: "position-selector",

    components: {PositionLine, TransitionToggle},

    emits: ['add', 'select'],

    props: {
        excludes: {
            type: Array,
            default: () => { return []; }
        }
    },

    computed: {
        ...mapGetters({
            knowledgeLevels: 'knowledgeLevel/all',
            positions: 'orgPosition/searchList',
            specializations: 'specialization/all',
            knowledgeLevelLoading: 'knowledgeLevel/loading',
            positionLoading: 'orgPosition/loadingSearch',
            specializationLoading: 'specialization/loading',
            noMore: 'orgPosition/searchEnd'
        }),

        filteredSpecializations: function () {
            return this.specializations.filter(s => s.position_id === this.selectedPosition?.id);
        },

        loading: function () {
            return this.knowledgeLevelLoading || this.positionLoading || this.specializationLoading;
        },

        empty: function () {
            return !this.selectedPosition;
        },
    },

    data: function () {
        return {
            selectedPosition: null,
            selectedSpecialization: null,
            selectedKnowledgeLevel: null,
            temp: '',
            search: '',
            prev: '',
        };
    },

    methods: {
        fetchKnowledgeLevels: function () {
            this.$store.dispatch('knowledgeLevel/all')
        },

        fetchPositions: function () {
            this.$store.dispatch('orgPosition/all', {with: ['hierarchyLevel']})
        },

        fetchSpecializations: function () {
            this.$store.dispatch('specialization/all', {position_id: this.selectedPosition?.id})
        },

        init: function () {
            if (!this.users?.length) {
                this.fetch(null)
            }
        },

        fetch: function (search) {
            this.$store.dispatch('orgPosition/search', {search: search, per_page: this.limit}).then(() => {
                this.prev = search;
            });
        },

        more: function () {
            this.$store.dispatch('orgPosition/moreSearch')
        },

        clear: function () {
            this.selected = [];
            this.$store.dispatch('orgPosition/clearSearch');
        },

        select: function (item = {}) {
            this.selectedPosition = item;
        },

        selectSpec: function (item = {}) {
            this.selectedSpecialization = item;
        },

        selectKnow: function (item = {}) {
            this.selectedKnowledgeLevel = item;
        },

        isSelected: function (id) {
            return this.selected.findIndex(s => s.id === id) > -1 || this.excludes.includes(id);
        },

        emitSelect: function () {
            this.$emit('select', {position: this.selectedPosition, specialization: this.selectedSpecialization, knowledgeLevel: this.selectedKnowledgeLevel});
            this.clear()
        },
    },

    watch: {
        search: {
            handler: function (value) {
                this.temp = value;
                _.debounce( (value) => {
                    if (this.temp === value) {
                        this.search = value;
                        this.fetch(value);
                    }
                }, 700)(value);
            },
        }
    },

    created() {
        this.fetchKnowledgeLevels()
        this.fetchPositions()
        this.fetchSpecializations()
    }
}
</script>

<template>
    <div class="user-selector bg-white pt-4 p-3 rounded">
        <div class="row mb-1">
            <div class="col-12 mb-3">
                <div class="row">
                    <div class="col-lg-3">
                        <b-button :disabled="empty" :title="$t('base.add').ucFirst()" class="w-100 text-center" variant="outline-light" @click="emitSelect">
                            <i class="mdi mdi-chevron-left"></i>
                        </b-button>
                    </div>
                    <div class="col-lg-9">
                        <span class="d-inline-block">
                            <span v-if="selectedPosition?.id" class="badge me-2 cursor-pointer" :style="{backgroundColor: selectedPosition?.color}" @click="select()">
                                <i v-if="selectedPosition?.icon" :class="selectedPosition.icon" class="me-1"></i>
                                <span>{{ selectedPosition.translation_key ? $t(selectedPosition.translation_key).ucFirst() : selectedPosition.name }}</span>
                            </span>
                            <span v-if="selectedSpecialization?.id" class="badge me-2 cursor-pointer" :style="{backgroundColor: selectedSpecialization?.color}" @click="selectSpec()">
                                <i v-if="selectedSpecialization?.icon" :class="selectedSpecialization.icon" class="me-1"></i>
                                <span>{{ selectedSpecialization.translation_key ? $t(selectedSpecialization.translation_key).ucFirst() : selectedSpecialization.name }}</span>
                            </span>
                            <span v-if="selectedKnowledgeLevel?.id" class="badge me-2 cursor-pointer" :style="{backgroundColor: selectedKnowledgeLevel?.color}"  @click="selectKnow()">
                                <i v-if="selectedKnowledgeLevel?.icon" :class="selectedKnowledgeLevel.icon" class="me-1"></i>
                                <span>{{ selectedKnowledgeLevel.translation_key ? $t(selectedKnowledgeLevel.translation_key).ucFirst() : selectedKnowledgeLevel.name }}</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="search-box bg-white">
                    <div class="position-relative">
                        <input v-model="search" @focus="init" type="text" class="form-control bg-light border-0" :placeholder="$t('base.search').ucFirst() + ' ...'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" class="eva eva-search-outline search-icon"><g data-name="Layer 2"><g data-name="search"><rect width="24" height="24" opacity="0"></rect><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <div class="col-12 my-4">
                <h6 class="text-muted mb-0">{{ $tc('organizations::positions.position', 2).ucFirst() }} | {{ $tc('organizations::specializations.specialization', 2) }}</h6>
            </div>
            <div class="col-12">
                <transition-toggle>
                    <div v-if="positions?.length" class="max-h-32 overflow-y-scroll">
                        <template v-for="(position, index) in positions" :key="index">
                            <position-line
                                class="cursor-pointer mb-1"
                                :class="{'bg-light': position.id === selectedPosition?.id}"
                                :position="position" @click="select(position)"
                            ></position-line>
                            <transition-toggle>
                                <div v-if="position?.id === selectedPosition?.id">
                                    <position-line v-for="(specialization) in filteredSpecializations"
                                                   :key="specialization.id"
                                                   :position="specialization"
                                                   class="ms-4 cursor-pointer mb-1"
                                                   :class="{'bg-light': specialization.id === selectedSpecialization?.id}"
                                                   @click="selectSpec(specialization)"
                                    >
                                    </position-line>
                                </div>
                            </transition-toggle>
                        </template>
                    </div>
                </transition-toggle>
                <div class="">
                    <transition-toggle>
                        <b-button variant="outline-light" class="w-100 mt-2" v-if="!noMore && positions?.length" @click="more" :disabled="loading">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>{{ $t('base.more').ucFirst() }}</span>
                        </b-button>
                    </transition-toggle>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-12">
                <h6 class="text-muted mb-0">{{ $tc('organizations::knowledge_levels.knowledge_level', 2).ucFirst() }}</h6>
            </div>
        </div>
        <div class="row pt-2 rounded">
            <div class="col-12">
                <position-line v-for="(item) in knowledgeLevels"
                               :key="item.id"
                               :position="item"
                               class="cursor-pointer mb-1"
                               :class="{'bg-light': item.id === selectedKnowledgeLevel?.id}"
                               @click="selectKnow(item)"
                >
                </position-line>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
