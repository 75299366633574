<script>
export default {
    name: "position-line",

    props: {
        position: {
            type: Object,
            required: true
        },

        withoutDescription: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            showModal: false,
            title: null,
            content: null
        }
    },

    methods: {
        readDescription: function (position) {
            this.title = position?.translation_key ? this.$t(position.translation_key).ucFirst() : position?.name;
            this.content = position.description;
            this.showModal = true;
        },
    }
}
</script>

<template>
    <div class="d-flex justify-content-between align-items-center border border-light rounded py-1 px-2">
        <div class="d-flex align-items-center">
            <div v-if="position?.icon" class="me-2" :style="{color: position?.color}">
                <i :class="position.icon"></i>
            </div>
            <div class="text">
                <span>{{ position?.translation_key ? $t(position.translation_key).ucFirst() : position?.name }}</span>
            </div>
        </div>
        <div v-if="!withoutDescription && position?.description" class="description">
            <i class="bx bx-detail" @click.stop="readDescription(position)"></i>
        </div>
        <b-modal v-model="showModal" :title="title" hide-footer>
            {{ content }}
        </b-modal>
    </div>
</template>

<style scoped>

</style>
