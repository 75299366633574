<script>
import {mapGetters} from "vuex";
import PositionCard from "../position-card.vue";
import PositionSelector from "./position-selector.vue";

export default {
    name: "position-manager",

    components: {PositionSelector, PositionCard},

    props: {
        userId: {
            type: [String, Number]
        }
    },

    computed: {
        ...mapGetters({
            loading: 'user/loadingPositions',
            userPositions: 'user/positions',
            pivots: 'user/positionPivots'
        }),

        excludes: function () {
            return this.userPositions?.map(m => m.id) || [];
        },
    },

    methods: {
        sync: function (pivots) {
            this.$store.dispatch('user/syncPositions', {id: this.userId, pivots: pivots})
        },

        add: function ({position, specialization, knowledgeLevel}) {
            const id = position?.id;
            const pivot = {};

            pivot[id] = {user_id: this.userId, specialization_id: specialization?.id, knowledge_level_id: knowledgeLevel?.id};
            this.sync(pivot)
        },

        fetch: function () {
            this.$store.dispatch('user/positions', this.userId);
        },
    },

    watch: {
        userId: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.fetch();
                }
            },
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{ $tc('organizations::positions.position', 2).ucFirst() }}</h5>
        </div>
        <div class="card-body bg-light rounded-bottom">
            <div class="row">
                <div class="col-lg-6">
                    <b-overlay :show="loading">
                        <position-card v-for="(userPosition) in userPositions" :key="userPosition.id" :position="userPosition"></position-card>
                    </b-overlay>
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body p-1">
                            <position-selector :excludes="excludes" @select="add"></position-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
