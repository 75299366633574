<script>
export default {
    name: "tab-card",

    props: {
        items: {
            type: Array
        }
    },

    data: function () {
        return {
            tabIndex: 0
        }
    },

    methods: {
        getName: function (item, index) {

            return (item.name || (index + 1) + this._.uid);
        },

        getIcon: function (item) {
            return item.icon || 'fas fa-question-circle';
        },

        getTitle: function (item) {
            return (item.title || item.name || '?')?.ucFirst();
        },

        setActive: function (index) {
            this.tabIndex = index;
        },
    }
}
</script>

<template>
    <div class="card">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <slot name="nav" v-bind:items="items" v-bind:setActive="setActive">
                <li class="nav-item" v-for="(item, index) in items" :key="index" @click="setActiveindex">
                    <slot :name="'nav' + getName(item, index)">
                        <a
                            class="nav-link"
                            :class="{'active': index === 0}"
                            data-bs-toggle="tab"
                            :href="'#' + getName(item, index)"
                            role="tab"
                        >
                            <i class="font-size-20" :class="getIcon(item)"></i>
                            <span class="d-none d-sm-block">{{ getTitle(item) }}</span>
                        </a>
                    </slot>
                </li>
            </slot>
        </ul>
    </div>
    <!-- Tab content -->
    <div class="tab-content">
        <slot name="content" v-bind:active="tabIndex">
            <div class="card">
                <div v-for="(item, index) in items" :key="index" class="tab-pane" :class="{'active': index === 0}" :id="getName(item, index)" role="tabpanel">
                    <slot :name="getName(item, index)" v-bind:itemid="item" v-bind:active="index === tabIndex">
                        {{ item.content }}
                    </slot>
                </div>
            </div>
        </slot>
    </div>
</template>

<style scoped>

</style>
