<template>
    <div class="row">
        <div class="col-xl-6">
            <div v-if="owner?.id" class="card">
                <b-overlay v-model="loading">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('users.superior').ucFirst() }}</h4>
                    </div>
                    <div class="card-body">
                        <b-form @submit.prevent>
                            <div class="col-12">
                                <label
                                    :class="{'text-danger': states.superior_id === false}"
                                    class="form-label"
                                    for="superior_id"
                                >{{ $t('users.columns.superior_id').ucFirst() }}</label>
                                <select
                                    id="superior_id"
                                    v-model="model.superior_id"
                                    :class="{'border-danger': states.superior_id === false}"
                                    class="form-select"
                                    name="superior_id"
                                >
                                    <option :value="owner.id ? null : undefined">
                                        {{ $t('users.placeholders.superior_id').ucFirst() }}
                                    </option>
                                    <option v-for="(user, key) in superiors" :key="key" :value="user.id">{{
                                            user.name
                                        }}
                                    </option>
                                </select>
                                <div :class="{'d-block': states.superior_id === false}" class="invalid-feedback">
                                    {{ errors.superior_id }}
                                </div>
                            </div>
                        </b-form>
                    </div>
                    <!-- end card body -->
                    <div class="card-footer">
                        <b-button variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                    </div>
                </b-overlay>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
        <div class="col-xl-6">
            <div v-if="owner?.id" class="card">
                <b-overlay v-model="loading">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('users.subordinates').ucFirst() }}</h4>
                    </div>
                    <div class="card-body">
                        <b-form @submit.prevent>
                            <div class="col-12">
                                <label
                                    :class="{'text-danger': states.superior_id === false}"
                                    class="form-label"
                                    for="superior_id"
                                >{{ $t('users.columns.superior_id').ucFirst() }}</label>
                                <multiple-select id="subordinates" v-model="subordinates" :store="store" action="potentialSubordinates" :query="{ id: owner?.id }"></multiple-select>
                                <div :class="{'d-block': states.superior_id === false}" class="invalid-feedback">
                                    {{ errors.superior_id }}
                                </div>
                            </div>
                        </b-form>
                    </div>
                    <!-- end card body -->
                    <div class="card-footer">
                        <b-button variant="primary" @click="syncSubordinates">{{ $t('base.save').ucFirst() }}</b-button>
                    </div>
                </b-overlay>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>

<script>
import MultipleSelect from "../elements/multiple-select.vue";

export default {
    name: "SuperiorForm",

    components: {MultipleSelect},

    emits: ['saved'],

    props: {
        owner: {
            type: Object,
            required: true
        },

        store: {
            type: String,
            default: 'user'
        }
    },

    computed: {
        loading: function () {
            return this.$store.getters[`${this.store}/loading`];
        },

        authenticated: function () {
            return this.$store.getters[`${this.store}/authenticated`];
        },

        states: function () {
            return this.$store.getters[`${this.store}/states`];
        },

        errors: function () {
            return this.$store.getters[`${this.store}/errors`];
        },
    },

    data: function () {
        return {
            model: {id: null, superior_id: null},
            subordinates: [],
            superiors: [],
            users: []
        }
    },

    methods: {
        fetchSubordinates: function () {
            this.$store.dispatch(`${this.store}/subordinates`, {id: this.owner?.id }).then(response => {
                this.subordinates = response
            })
        },

        fetchSuperiors: function () {
            this.$store.dispatch(`${this.store}/potentialSuperiors`, {id: this.owner?.id }).then(response => {
                this.superiors = response
            })
        },

        syncSubordinates: function () {
            this.$store.dispatch(`${this.store}/syncSubordinates`, { id: this.owner?.id, data: {subordinates: this.subordinates} })
        },

        save: function () {

            return this.$store.dispatch(`${this.store}/update`, this.model).then(saved => {
                this.model.superior_id = saved?.superior_id
                if (this.authenticated?.id === this.owner?.id) {
                    this.$store.dispatch(`${this.store}/setAuthenticated`, saved)
                }
                this.$emit('saved')
            })
        },
    },

    watch: {
        owner: {
            immediate: true,
            handler: function (user) {
                this.model.superior_id = user?.superior_id
                this.model.id = user?.id
                this.fetchSuperiors()
                this.fetchSubordinates()
            }
        },
    }
}
</script>

<style scoped>
@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
