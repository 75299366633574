<script>
export default {
    name: "tab-panel",

    props: {
        active: {
            type: Boolean,
            default:false
        },

        name: {
            type: String
        },

        content: {
            type: String
        }
    }
}
</script>

<template>
    <div class="tab-pane" :class="{'active': active}" :id="name" role="tabpanel">
        <slot>
            <div v-html="content"></div>
        </slot>
    </div>
</template>

<style scoped>

</style>
