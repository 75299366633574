<script>
export default {
    name: "tab-nav",

    props: {
        active: {
            type: Boolean,
            default: false
        },

        name: {
            type: String,
            required: true
        },

        icon: {
            type: String,
            default: 'fas fa-question-circle'
        },

        text: {
            type: String
        }
    }
}
</script>

<template>
    <li class="nav-item">
        <a
            class="nav-link"
            :class="{'active': active}"
            data-bs-toggle="tab"
            :href="'#' + name"
            role="tab"
        >
            <slot name="icon">
                <i class="font-size-20" :class="icon"></i>
            </slot>
            <span class="d-none d-sm-block">{{ text }}</span>
        </a>
    </li>
</template>

<style scoped>

</style>
