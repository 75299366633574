<script>
export default {
    name: "skill-card",

    emits: ['remove'],

    props: {
        skill: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            showModal: false,
            title: null,
            content: null
        }
    },

    methods: {
        readDescription: function (position) {
            this.title = position?.translation_key ? this.$t(position.translation_key).ucFirst() : position?.name;
            this.content = position.definition;
            this.showModal = true;
        },

        remove: function () {
            this.$emit('remove')
        },
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body rounded bg-gradient py-2">
            <div class="d-flex justify-content-between w-100 pe-4">
                <div class="d-flex align-items-center w-100">
                    <div class="me-3">
                        <div v-if="skill?.icon" class="font-size-18 me-2" :style="{color: skill?.color}">
                            <i :class="skill.icon"></i>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="position d-flex justify-content-between w-100">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <p class="font-weight-bold mb-0">
                                    <span>{{ skill?.translation_key ? $t(skill.translation_key).ucFirst() : skill?.name }}</span>
                                </p>
                                <div v-if="skill?.definition" class="description cursor-pointer">
                                    <i class="bx bx-detail text-info" @click.stop="readDescription(skill)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-absolute positioned text-danger">
                <span class="p-1 cursor-pointer" @click="remove">
                    <i class="fas fa-times"></i>
                </span>
            </div>
            <b-modal v-model="showModal" :title="title" hide-footer>
                {{ content }}
            </b-modal>
        </div>
        <div class="card-footer py-0 bg-light text-center">
            <small>
                <i v-if="skill?.skill_level?.icon" class="me-2" :class="skill.skill_level.icon" :style="{color: skill?.skill_level?.color}"></i>
                <span>{{ skill?.skill_level?.translation_key ? $t(skill.skill_level.translation_key).ucFirst() : skill?.skill_level?.name }}</span>
            </small>
        </div>
    </div>
</template>

<style scoped>
    .positioned {
        top: 3px;
        right: 10px;
    }
</style>
